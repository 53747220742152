import reducer from './js/app/reducer';
import './SchemeColor';
import './resources/custom-script.js';

window.kinosite = {
	reducer,
	additionalModules: {
		detailsPage: { getData: () => import('./js/templates/details.html') },
	},
};
