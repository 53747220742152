import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '196, 35, 42',
		'primary-dark': '170, 31, 37',
		'accent': '255, 171, 0',
		'accent-plus': '0, 0, 0',
	},
});
